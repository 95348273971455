<template>
  <div id="divRegulationDetailsWrapper" class="static-page-holder">
    <VueWrapperNarrow>
      <div class="rd-wrapper" v-html="content"></div>
    </VueWrapperNarrow>
  </div>
</template>

<script>
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StaticContent from '@/services/Api/staticContents';
export default {
  name: 'RegulationDetails',
  components: { VueWrapperNarrow },
  data() {
    return { content: '' };
  },
  created() {
    StaticContent.getStaticContent('RegulasyonDetaylari')
      .then(res => {
        const {
          Data: { content },
        } = res.data;
        this.content = content;
      })
      .catch(err => console.error(err));
  },
};
</script>

<style scoped lang="scss">
.rd-wrapper {
  padding: palette-space-level('60') 0;
  white-space: pre-line;
}
</style>
